.fade_in {
    visibility: visible;
    opacity: 1;
    bottom:20px;
    transition: opacity 0.5s linear, bottom 0.5s;
  }
.fade_out {
    visibility: hidden;
    opacity: 0;
    bottom:0;
    transition: visibility 0s 0.5s, opacity 0.5s linear, bottom 0.5s;
}