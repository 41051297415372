@font-face {
	font-family: "Work Sans";
	font-weight: 400;
	font-display: swap;
	src: local("WorkSans-VariableFont_wght"),
		url("./assets/fonts/WorkSans-VariableFont_wght.ttf") format("truetype");
}

a{
    cursor:pointer;
    text-decoration:none;
}

*:focus{
	border:none;
	outline:none;
}

.ReactModal__Content {
	opacity: 0;
	transform: translate(-50%,-100px);
	transition: all 500ms ease-in-out;
}
  
.ReactModal__Content--after-open {
	opacity: 1;
	transform: translate(-50%, 20px);
}
  
.ReactModal__Content--before-close {
	opacity: 0;
	transform: translate(-50%,-100px);
}

